import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import bgimg from "../assets/2.webp";
import { WebsiteText } from "../constants/WebsiteText";
import axios from 'axios';
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";

// Function to get the CSRF token from cookies
const getCsrfToken = () => {
  const name = 'csrftoken';
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

// Function to log page visit
const logPageVisit = (pageUrl) => {
  const csrfToken = getCsrfToken();
  axios.get('https://ramsisindustry.com/api/log-visit/', {
    params: { page_url: pageUrl },
    headers: {
      'X-CSRFToken': csrfToken,
    },
  })
  .then(response => {
    console.log('Page visit logged:', response.data);
  })
  .catch(error => {
    console.error('Error logging page visit:', error);
  });
};

const Contactus = ({ contact, isArabic }) => {
  useEffect(() => {
    logPageVisit(window.location.pathname);
  }, []);

  useEffect(() => {
    gsap.fromTo(
      ".contact-heading",
      { opacity: 0, y: -50 },
      { opacity: 1, y: 0, duration: 1, ease: "power2.out" }
    );

    gsap.fromTo(
      ".contact-description",
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 1, ease: "power2.out", delay: 0.5 }
    );

    gsap.fromTo(
      ".contact-info",
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 1, ease: "power2.out", delay: 1 }
    );

    gsap.fromTo(
      ".contact-form",
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 1, ease: "power2.out", delay: 1.5 }
    );
  }, []);

  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = isArabic ? "يرجى إدخال الاسم" : "Please enter your name";
    }
    if (!formData.email) {
      errors.email = isArabic ? "يرجى إدخال البريد الإلكتروني" : "Please enter your email";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = isArabic ? "البريد الإلكتروني غير صالح" : "Email is invalid";
    }
    if (!formData.message) {
      errors.message = isArabic ? "يرجى إدخال الرسالة" : "Please enter your message";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const csrfToken = getCsrfToken();
      const response = await axios.post(
        'https://ramsisindustry.com/api/messages/', 
        formData, 
        {
          headers: {
            'X-CSRFToken': csrfToken,
          },
        }
      );
      console.log('Message sent:', response.data);
      setFormData({ name: '', email: '', message: '' });
      alert(isArabic ? "تم إرسال الرسالة بنجاح" : "Message sent successfully");
    } catch (error) {
      console.error('Error sending message:', error);
      alert(isArabic ? "حدث خطأ أثناء إرسال الرسالة" : "An error occurred while sending the message");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  return (
    <section>
      <div className="relative w-full h-96">
        <img
          className="absolute h-full w-full object-cover object-center"
          src={bgimg}
          alt="contact us image"
        />
        <div className="absolute inset-0 h-full w-full bg-white/50"></div>
        <div className="relative pt-28 text-center">
          <h2 className="contact-heading block antialiased tracking-normal font-sans font-semibold leading-[1.3] text-primarydark mb-4 text-3xl lg:text-4xl">
            {isArabic ? WebsiteText.contactHeadingAr : WebsiteText.contactHeading}
          </h2>
          <p className="contact-description block antialiased font-sans text-xl font-normal leading-relaxed text-primarydark mb-9 opacity-70">
            {isArabic ? WebsiteText.contactDescriptionAr : WebsiteText.contactDescription}
          </p>
        </div>
      </div>
      <div className="-mt-16 mb-8 px-8">
        <div className="container mx-auto">
          <div className="py-4 md:py-12 grid grid-cols-1 md:grid-cols-2 rounded-xl border border-white bg-white shadow-md shadow-black/5 saturate-200">
          <div className="contact-info my-12 md:my-48 grid gap-6 px-4 md:px-10 place-items-start text-primarydark">
            {/* Address Section */}
            <div className="flex items-center gap-4 ">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 fill-primarydark">
                <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path>
              </svg>
              <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-bold">
                {isArabic ? contact.address_ar : contact.address}
              </p>
            </div>

            {/* Non-International Numbers */}
            {contact.number.length > 0 &&
              contact.number
                .filter((num) => !num.is_international) // Filter non-international numbers
                .map((num) => (
                  <a
                    key={num.phone_number}
                    className={`hover:opacity-75 flex gap-2 items-center cursor-pointer z-10`}
                    href={num.has_whatsapp ? `https://wa.me/${num.phone_number}` : `tel:${num.phone_number}`}
                  >
                    {num.has_whatsapp ? (
                      <IoLogoWhatsapp className="w-5 h-5" />
                    ) : (
                      <FaPhoneAlt className="w-5 h-5" />
                    )}
                    {num.phone_number}
                  </a>
                ))}

            {/* Email Section */}
            <div className="flex items-center gap-4">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 fill-primarydark">
                <path fillRule="evenodd" d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 01-.375.65 2.249 2.249 0 000 3.898.75.75 0 01.375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 17.625v-3.026a.75.75 0 01.374-.65 2.249 2.249 0 000-3.898.75.75 0 01-.374-.65V6.375zm15-1.125a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V6a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0v.75a.75.75 0 001.5 0v-.75zm-.75 3a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0v-.75a.75.75 0 01.75-.75z" clipRule="evenodd"></path>
              </svg>
              <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-bold">
                {contact.email}
              </p>
            </div>

            {/* International Inquiries Section */}
            {contact.number.some((num) => num.is_international) && (
              <div className="flex flex-col gap-4 mt-4">
                <div>{isArabic ? 'خارج مصر' : 'For international inquiries'}</div>
                {contact.number
                  .filter((num) => num.is_international) // Filter international numbers
                  .map((num) => (
                    <a
                      key={num.phone_number}
                      className={`hover:opacity-75 flex gap-2 items-center cursor-pointer z-10`}
                      href={num.has_whatsapp ? `https://wa.me/${num.phone_number}` : `tel:${num.phone_number}`}
                    >
                      {num.has_whatsapp ? (
                        <IoLogoWhatsapp className="w-5 h-5" />
                      ) : (
                        <FaPhoneAlt className="w-5 h-5" />
                      )}
                      {num.phone_number}
                    </a>
                  ))}
              </div>
            )}

            
          </div>

            <div className="lg:px-8">
              <form className="contact-form mx-auto max-w-xl rounded-lg border border-gray-200 bg-white p-8 shadow-md" onSubmit={handleSubmit}>
                <h3 className="text-xl font-semibold text-gray-700 mb-6">
                  {isArabic ? WebsiteText.sendUsMessageAr : WebsiteText.sendUsMessage}
                </h3>
                <div className="grid gap-6 mb-6">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="name" className="text-sm font-semibold text-gray-700">
                      {isArabic ? WebsiteText.yourNameAr : WebsiteText.yourName}
                    </label>
                    <input
                      id="name"
                      type="text"
                      placeholder={isArabic ? WebsiteText.yourNameAr : WebsiteText.yourName}
                      className={`rounded border ${errors.name ? 'border-red-500' : 'border-gray-300'} p-3 text-gray-700`}
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="email" className="text-sm font-semibold text-gray-700">
                      {isArabic ? WebsiteText.yourEmailAr : WebsiteText.yourEmail}
                    </label>
                    <input
                      id="email"
                      type="email"
                      placeholder={isArabic ? WebsiteText.yourEmailAr : WebsiteText.yourEmail}
                      className={`rounded border ${errors.email ? 'border-red-500' : 'border-gray-300'} p-3 text-gray-700`}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="message" className="text-sm font-semibold text-gray-700">
                      {isArabic ? WebsiteText.yourMessageAr : WebsiteText.yourMessage}
                    </label>
                    <textarea
                      id="message"
                      rows="4"
                      placeholder={isArabic ? WebsiteText.yourMessageAr : WebsiteText.yourMessage}
                      className={`rounded border ${errors.message ? 'border-red-500' : 'border-gray-300'} p-3 text-gray-700`}
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    {errors.message && (
                      <p className="text-red-500 text-xs mt-1">{errors.message}</p>
                    )}
                  </div>
                </div>
                <button type="submit" className="w-full rounded bg-primarydark py-3 text-white font-semibold uppercase hover:bg-secondary">
                  {isArabic ? WebsiteText.sendMessageButtonAr : WebsiteText.sendMessageButton}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contactus;
