import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/17.webp";
import img2 from "../assets/2.webp";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { WebsiteText } from "../constants/WebsiteText";
import axios from 'axios';

const logPageVisit = (pageUrl) => {
  axios.get('https://ramsisindustry.com/api/log-visit/', { params: { page_url: pageUrl } })
    .then(response => {
      console.log('Page visit logged:', response.data);
    })
    .catch(error => {
      console.error('Error logging page visit:', error);
    });
};

const OurCompany = ({isArabic}) => {
  useEffect(()=>{logPageVisit(window.location.pathname);},[])
  gsap.registerPlugin(ScrollTrigger);

  const ourcompany1 = useRef(null);
  const ourcompany2 = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const animationConfig = {
        y: 200,
        opacity: 0,
        stagger: 0.3,
        duration: 1,
      };

      const createTimeline = (trigger, elements) => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: trigger,
              start: "10% bottom",
              end: "center center",
              markers: false,
              duration: 2,
            },
          })
          .from(elements, animationConfig);
      };

      createTimeline(
        ourcompany1.current,
        ourcompany1.current.querySelectorAll(".comanimate1")
      );
      createTimeline(
        ourcompany2.current,
        ourcompany2.current.querySelectorAll(".comanimate")
      );
    }, [ourcompany1, ourcompany2]);

    return () => ctx.revert();
  }, []);

  return (
    <section
      id="services"
      className="w-full bg-primarybg flex flex-col lg:flex-row py-[25%] lg:py-[10%]"
    >
      <div
        ref={ourcompany2}
        className={`w-full lg:w-1/2 flex ${isArabic?'flex-row-reverse':'flex-row'} gap-[4%] lg:gap-[10%] px-[5%]`}
      >
        <div className="comanimate flex flex-col justify-between w-full gap-[10%] h-full">
          <div className="bg-primarydark h-[40%] p-4">
            <div className="border-[1px] border-white h-full p-2 md:p-6 flex flex-col justify-between text-white">
              <h4 className="text-xs md:text-lg uppercase xl:text-2xl">{isArabic?WebsiteText.aboutTitleAR:WebsiteText.aboutTitle}</h4>
              <div className="w-2/5 border-b-[1px] my-1 lg:my-2 border-white"></div>
              <h4 className="text-xs xl:text-sm font-Roboto my-1 lg:my-2">
                {isArabic?WebsiteText.aboutTextAR:WebsiteText.aboutText}
              </h4>
              <Link
                to="/categories"
                className="text-[7px] uppercase md:text-base xl:text-sm my-1 lg:my-2 cursor-pointer hover:underline"
              >
                {isArabic?WebsiteText.aboutLinkAR:WebsiteText.aboutLink}
              </Link>
            </div>
          </div>
          <div className="h-full pt-[20%] md:pt-0">
            <img
              className="w-full h-full object-cover"
              src={img2}
              alt="Our Service"
            />
          </div>
        </div>
        <div className="comanimate flex flex-col justify-between w-full gap-[10%] h-full">
          <div className="h-[50%] mb-5 lg:mb-0 justify-self-start">
            <img
              className="w-full h-full object-cover"
              src={img1}
              alt="Our Location"
            />
          </div>
          <div className="bg-secondary h-[40%] p-4">
            <div className="border-[1px] border-white h-full p-4 md::p-6 flex flex-col justify-between text-white">
              <h4 className="text-xs uppercase md:text-lg xl:text-2xl">{isArabic?WebsiteText.aboutTitle2AR:WebsiteText.aboutTitle2}</h4>
              <div className="w-2/5 border-b-[1px] my-1 lg:my-2 border-white"></div>
              <h4 className="text-xs xl:text-sm font-Roboto my-1 lg:my-2">
                {isArabic?WebsiteText.aboutText2AR:WebsiteText.aboutText2}
              </h4>
              <Link
                to="/contact"
                className="text-[7px] uppercase md:text-base xl:text-sm my-1 lg:my-2 cursor-pointer hover:underline"
              >
                {isArabic?WebsiteText.aboutLink2AR:WebsiteText.aboutLink2}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div ref={ourcompany1} className={`w-full lg:w-1/2 px-[6%] text-primarydark flex flex-col ${isArabic?'text-end items-end':'text-start items-start'}`}>
        <div className="comanimate1 pt-20 text-lg text-primary">
          {isArabic?WebsiteText.aboutMainSubheaderAR:WebsiteText.aboutMainSubheader}
        </div>
        <h1 className="comanimate1 font-bold leading-tight uppercase text-[25px] lg:text-[50px]">
        {isArabic?WebsiteText.aboutMainHeaderAR:WebsiteText.aboutMainHeader}
        </h1>
        <div className="comanimate1 w-1/6 border-b-[3px] py-2 mb-6 border-secondary"></div>
        <p className="comanimate1 mb-10 font-Roboto text-subtext">
          {isArabic?WebsiteText.aboutMainTextAR:WebsiteText.aboutMainText}
        </p>
        <Link
          to="/categories"
          className="comanimate1 uppercase text-white text-center px-4 py-4 my-8 text-base lg:w-1/3 rounded-full tracking-widest bg-primarydark hover:bg-secondary transition-colors duration-500 cursor-pointer"
        >
          {isArabic?WebsiteText.aboutMainButtonAR:WebsiteText.aboutMainButton}
        </Link>
      </div>
    </section>
  );
};

export default OurCompany;
