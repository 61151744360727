import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { WebsiteText } from "../constants/WebsiteText";

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const Contact = ({ isArabic }) => {
  // Create refs for elements you want to animate
  const formRef = useRef(null);
  const mapRef = useRef(null);

  // State to manage form data
  const [formData, setFormData] = useState({
    email: "",
    message: ""
  });

  // Function to get CSRF token from cookies
  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const csrftoken = getCookie('csrftoken');  // Get the CSRF token from cookies

    try {
      const response = await axios.post(
        'https://ramsisindustry.com/api/messages/',
        formData,
        {
          headers: {
            'X-CSRFToken': csrftoken,  // Include CSRF token in the request headers
            'Content-Type': 'application/json',
          }
        }
      );
      console.log('Message sent:', response.data);
      // Optionally reset form
      setFormData({ email: '', message: '' });
      alert(isArabic ? "تم إرسال الرسالة بنجاح" : "Message sent successfully");
    } catch (error) {
      console.error('Error sending message:', error);
      alert(isArabic ? "حدث خطأ أثناء إرسال الرسالة" : "An error occurred while sending the message");
    }
  };

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    // Animation for the contact form
    gsap.fromTo(
      formRef.current,
      { opacity: 0, y: 50 }, // Start state
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: formRef.current,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      }
    );

    // Animation for the map
    gsap.fromTo(
      mapRef.current,
      { opacity: 0, scale: 0.8 }, // Start state
      {
        opacity: 1,
        scale: 1,
        duration: 1,
        ease: "power3.out",
        delay: 0.5,
        scrollTrigger: {
          trigger: mapRef.current,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      }
    );
  }, []);

  return (
    <section
      className={`text-gray-600 body-font relative mt-20 flex flex-col ${
        isArabic ? "text-end items-end" : "text-start items-start"
      }`}
    >
      <div className="container px-5 py-24 mx-auto flex">
        <div
          ref={formRef}
          className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md"
        >
          <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
            {isArabic ? WebsiteText.contactHeaderAR : WebsiteText.contactHeader}
          </h2>
          <p className="leading-relaxed mb-5 text-gray-600">
            {isArabic
              ? WebsiteText.contactSubHeaderAR
              : WebsiteText.contactSubHeader}
          </p>
          <form onSubmit={handleSubmit}>
            <div className="relative mb-4">
              <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                {isArabic ? WebsiteText.contactMailAR : WebsiteText.contactMail}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-600"
              >
                {isArabic
                  ? WebsiteText.contactMessageAR
                  : WebsiteText.contactMessage}
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button type="submit" className="text-white bg-primarydark border-0 py-2 px-6 focus:outline-none hover:bg-secondary rounded text-lg">
              {isArabic ? WebsiteText.contactSendAR : WebsiteText.contactSend}
            </button>
          </form>
        </div>
      </div>
      <div ref={mapRef} className="relative w-full h-full lg:absolute inset-0 bg-gray-300 z-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27625.373619880214!2d31.21860367431641!3d30.060612400000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145840849e8402a3%3A0xcabd9112616c50b2!2sRamsis%20Industry%2C%20Trading%20%26%20Import!5e0!3m2!1ses!2seg!4v1722776811691!5m2!1ses!2seg"
          className="w-full h-full"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Map"
        ></iframe>
      </div>
    </section>
  );
};

export default Contact;
