import React, { useState, useEffect } from 'react';

const CookieCard = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check localStorage to see if the user has already made a choice
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);  // Show the modal if no choice has been made
    }
  }, []);

  const handleAccept = () => {
    // Handle logic for accepting all cookies
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
    console.log("All cookies accepted");
  };

  const handleReject = () => {
    // Handle logic for rejecting all cookies
    localStorage.setItem('cookieConsent', 'rejected');
    setIsVisible(false);
    console.log("All cookies rejected");
  };

  const handlePreferences = () => {
    // Handle logic for opening preferences (you can customize this)
    console.log("Opening cookie preferences");
  };

  const handleClose = () => {
    // Close the modal and set default settings
    setIsVisible(false);
    console.log("Cookie consent closed with default settings");
  };

  return isVisible ? (
    <section className="fixed max-w-md p-4 z-[99999] mx-auto bg-white border border-gray-200 dark:bg-gray-800 left-12 bottom-16 dark:border-gray-700 rounded-2xl">
      <h2 className="font-semibold text-gray-800 dark:text-white">🍪 We use cookies!</h2>

      <p className="mt-4 text-sm text-gray-600 dark:text-gray-300">
        Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. 
      </p>

      <p className="mt-3 text-sm text-gray-600 dark:text-gray-300">
        Closing this modal default settings will be saved.
      </p>

      <div className="grid grid-cols-2 gap-4 mt-4 shrink-0">
        <button 
          className="text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
          onClick={handleAccept}
        >
          Accept all
        </button>

        <button 
          className="text-xs border text-gray-800 hover:bg-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors focus:outline-none"
          onClick={handleReject}
        >
          Only essential cookies
        </button>

        
      </div>
    </section>
  ) : null;
};

export default CookieCard;
