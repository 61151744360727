import React, { useState } from 'react';
import Modal from './Modal'; // Assuming Modal is in the same folder
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({ events, isArabic }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleOpenModal = (event) => {
    setSelectedEvent(event); // Open modal with the selected event
  };

  const handleCloseModal = () => {
    setSelectedEvent(null); // Close the modal
  };

  // Custom Next Button
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="slick-next"
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          zIndex: 10,
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          fontSize: '30px',
        }}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 320 512"
          height="3em"
          width="3em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
        </svg>
      </div>
    );
  };

  // Custom Prev Button
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="slick-prev"
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          zIndex: 10,
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          fontSize: '30px',
        }}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 320 512"
          height="2.5em"
          width="2.5em"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: 'rotate(180deg)',
          }}
        >
          <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
        </svg>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,        // Allow users to drag through multiple slides
    adaptiveHeight: false,       // Automatically adjust height for each slide
    autoplay: true,               // Enable auto-scrolling
    autoplaySpeed: 3000,          // Auto-scroll every 3 seconds
    nextArrow: <NextArrow />,     // Custom right arrow
    prevArrow: <PrevArrow />,     // Custom left arrow

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full my-10 py-20 h-full  relative">
    {isArabic?
        <h2 className="text-3xl md:text-6xl text-center uppercase font-bold text-primarydark mb-20">
        <span className="text-secondary">أحدث</span> مشاركاتنا
      </h2>:
      <h2 className="text-3xl md:text-6xl text-center uppercase font-bold text-primarydark mb-20">
        <span className="text-secondary">Latest</span> Events
      </h2>}
      <Slider {...settings}>
        {events.map((event, index) => (
          <div key={index} className="px-4 h-full">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <img
                src={event.images}
                alt={event.name}
                className="w-full h-64 object-cover"
              />
              <div className="p-4">
                <h2 className="text-2xl font-semibold mb-2">{isArabic ? event.name_ar : event.name}</h2>
                <button
                  className="inline-block py-3 px-6 bg-primarydark text-white font-bold text-xs uppercase rounded-full hover:bg-primary cursor-pointer transition-colors duration-300"
                  onClick={() => handleOpenModal(event)}
                >
                  {isArabic?'المزيد':'See More'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* Modal for displaying the event details */}
      {selectedEvent && (
        <Modal isOpen={!!selectedEvent} onClose={handleCloseModal}>
          <div className="p-10 ">
            <img
              src={selectedEvent.images}
              alt={selectedEvent.name}
              className="w-full h-64 object-cover rounded-md mb-4"
            />
            <h2 className="text-3xl font-bold mb-2">{isArabic ? selectedEvent.name_ar : selectedEvent.name}</h2>
            <p className="text-gray-700">{isArabic ? selectedEvent.description_ar : selectedEvent.description}</p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Carousel;
