import React, { useEffect, useRef, useState } from "react";
import bgImg from "../assets/MAPRAMSIS.png";
import gsap from "gsap";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaHandshake } from "react-icons/fa6";
import { WebsiteText } from "../constants/WebsiteText";

const WhyUs = ({isArabic}) => {
  const [selectedItem, setSelectedItem] = useState(1);
  const [aboutList, setAboutList] = useState([
    {
      title: "competitive prices",
      text: "We offer very competitive prices with high quality products to support our customers worldwide.",
      id: 1,
    },
    {
      title: "global trade routes",
      text: "Egypt is at the heart of global trade routes, thus it provides easy access to Europe, Middle East and Africa.",
      id: 2,
    },
    {
      title: "trade agreements",
      text: "Egypt has trade agreements with Arab countries, European Union and COMESA countries which help cutting cost down.",
      id: 3,
    },
  ]);

  
  useEffect(() => {
    setAboutList(isArabic?WebsiteText.sec2arrayAR:WebsiteText.sec2array)
  }, [isArabic]);

  const titleRef = useRef(null);
  const textRef = useRef(null);
  const bgRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: -100 },
      { opacity: 1, y: 0, duration: 0.5, ease: "power2.out", delay: 0.4 }
    );
    gsap.fromTo(
      textRef.current,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0, duration: 0.5, ease: "power2.out", delay: 0.4 }
    );
    gsap.fromTo(
      bgRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1, ease: "power2.out" }
    );
  }, [selectedItem]);
  if (!aboutList) {
    return <div>Loading...</div>; 
  }
  return (
    <div id="about" className="px-10 flex flex-col items-center w-full z-50 mt-20">
      {isArabic?
        <h2 className="text-3xl md:text-6xl uppercase font-bold text-primarydark mb-20">
        <span className="text-secondary">لماذا</span> رمسيس
      </h2>:
      <h2 className="text-3xl md:text-6xl uppercase font-bold text-primarydark mb-20">
        <span className="text-secondary">Why</span> RAMSIS
      </h2>}
      <div className="w-full flex flex-col md:flex-row h-full">
        <div className="w-full md:w-1/3 flex flex-col justify-between">
          <ImgText
            text={aboutList[0].title}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            item={1}
          />
          <ImgText
            text={aboutList[1].title}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            item={2}
          />
          <ImgText
            text={aboutList[2].title}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            item={3}
          />
        </div>
        <div className="w-full md:w-2/3 md:ml-[6%] h-full relative">
          <img className=" border-l-[20px] border-secondary" src={bgImg} alt="deals map" />
          <div
            ref={bgRef}
            className="w-[75%] md:w-[40%] h-full ml-[20px] bg-primarydark bg-opacity-80 top-0 left-0 absolute flex flex-col justify-center items-start text-white pl-[15px] md:pl-[35px] pr-[10px]"
          >
            <h5 ref={titleRef} className="text-base mb-4 font-bold uppercase">
              {aboutList[selectedItem - 1].title}
            </h5>
            <p ref={textRef} className="text-sm">
              {aboutList[selectedItem - 1].text}
            </p>
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default WhyUs;

const ImgText = ({ text, selectedItem, setSelectedItem, item }) => {
  return (
    <div
      onMouseEnter={() => setSelectedItem(item)}
      className="flex justify-between items-center mb-8 cursor-pointer z-50"
    >
      <div className={`rounded-full border-4 transition-all duration-1000  ${selectedItem === item ? 'border-secondary ' : 'border-transparent'}`}>
        {item===1?<FaMoneyBillWave className="w-20 h-20 fill-primarydark p-2" />:item===2?<FaMapLocationDot className="w-20 h-20 fill-primarydark p-2" />:<FaHandshake className="w-20 h-20 fill-primarydark p-2" />}
      </div>

      <div className="flex justify-start items-start w-1/2">
        <h4
          className={`text-secondary text-xl uppercase font-bold border-b-4 text-start transition-all duration-1000 ${
            selectedItem === item ? "border-secondary" : "border-transparent"
          }`}
        >
          {text}
        </h4>
      </div>
    </div>
  );
};

const SectionTitle = ({ title }) => {
  return (
    <h2 className="text-3xl md:text-6xl uppercase font-bold text-primarydark mb-20">
      {title}
    </h2>
  );
};
