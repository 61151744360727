import React, { useEffect } from "react";
import ProductCard2 from "./ProductCard2";
import { gsap } from "gsap";
import { useParams } from "react-router-dom";

const CategoryPage = ({ productsData, isArabic }) => {
  const { categoryId } = useParams();
  
  const category = productsData
    .flatMap((brand) => brand.categories)
    .find((cat) => cat.id === parseInt(categoryId));

  useEffect(() => {
    gsap.from(".product-card", {
      opacity: 0,
      y: 50,
      stagger: 0.2,
      duration: 1,
      ease: "power3.out",
    });
  }, [category]);

  return (
    <section className="bg-white my-24 text-right flex flex-col items-center">
      {/* Header Section */}
      <div className="w-[100%]  bg-center bg-no-repeat">
        <div>
          <img
              src={category.image || 'https://via.placeholder.com/150'}
              alt="Cover"
              className="w-full h-[80vh] object-cover  "
            />
            <div className="w-full  absolute inset-0 bg-black opacity-25 h-[80vh] mt-24"></div>
            <div className="w-full flex flex-col items-center justify-center absolute inset-0  h-[80vh] mt-24">
              <h2 className="text-6xl md:text-8xl text-wrap text-center font-bold mb-6 text-white">
                {isArabic ? category?.name_ar : category?.name} 
              </h2>
              <p className="text-white text-wrap text-center mb-6">
                {isArabic ? category?.description_ar : category?.description} 
              </p>
              </div>
        </div>
      
    </div>

      {/* Products Section */}
      <div className="container px-6 py-8 mx-auto">
        <div className="grid grid-cols-1 gap-8 mt-8">
          {category?.products.map((product) => (
            <ProductCard2
              key={product.id}
              product={product}
              isArabic={isArabic}
              className="product-card"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CategoryPage;
