export const WebsiteText = 
{
    "heroHead": "Making organization easy!",
    "heroHeadAr": "نجعل التنظيم سهلاً",
    "heroText": "Our products vary from archiving solution products, presentation products and ring binders to notebooks and desk calendars used by the most renowned organizations in Egypt, Africa, and the Middle East as we are also looking to expand our footprint beyond that.",
    "heroTextAR": "منتجاتنا تتنوع بين منتجات حلول الأرشفة ومنتجات العروض ومجلدات الحلقات إلى الدفاتر والتقويمات المكتبية التي تستخدمها أشهر المؤسسات في مصر وأفريقيا والشرق الأوسط، ونحن نسعى أيضاً لتوسيع تواجدنا إلى ما وراء ذلك.",
    "footerText":"A home for leading and satisfying people’s needs for office organization, committed to providing premium quality products. We aim to help everyone organize their documents to have a better office experience every day. ",
    "footerTextAR":"مكان لتلبية احتياجات الناس في التنظيم المكتبي، ملتزمون بتقديم منتجات عالية الجودة. نحن نسعى لمساعدة الجميع في تنظيم مستنداتهم للحصول على تجربة مكتب أفضل كل يوم.",
    "button1": "Our Products",
    "button1Ar": "منتجاتنا",
    "button2":"About us",
    "button2Ar":"من نحن",
    "twoSideSectionHeading": "FULL RANGE OF\nSTATIONERY ​PRODUCTS",
    "twoSideSectionHeadingAr": "مجموعة كاملة من منتجات الأدوات المكتبية",
    
    "twoSideSectionDescription": "Our products vary from archiving solution products, presentation products and ring binders to notebooks and desk calendars used by the most renowned organizations in Egypt, Africa, and the Middle East as we are also looking to expand our footprint beyond that.",
    "twoSideSectionDescriptionAr": "تتنوع منتجاتنا من حلول الأرشفة، ومنتجات العرض والمجلدات الحلقية إلى الدفاتر وتقويمات المكاتب المستخدمة من قبل أشهر المؤسسات في مصر وأفريقيا والشرق الأوسط ونحن نسعى أيضًا لتوسيع نطاق وجودنا إلى أبعد من ذلك.",
    
    "contactHeading": "Contact Information",
    "contactHeadingAr": "معلومات الاتصال",

    "contactDescription": "We're waiting to hear from you.",
    "contactDescriptionAr": "نحن في انتظار سماع أخبارك.",

    "addressLabel": "Address",
    "addressLabelAr": "العنوان",

    "phoneLabel": "Phone",
    "phoneLabelAr": "الهاتف",

    "emailLabel": "Email",
    "emailLabelAr": "البريد الإلكتروني",

    "sendUsMessage": "Send Us a Message",
    "sendUsMessageAr": "أرسل لنا رسالة",

    "yourName": "Your Name",
    "yourNameAr": "اسمك",

    "yourEmail": "Your Email",
    "yourEmailAr": "بريدك الإلكتروني",

    "yourMessage": "Your Message",
    "yourMessageAr": "رسالتك",

    "sendMessageButton": "Send Message",
    "sendMessageButtonAr": "إرسال الرسالة",

    
    "browseProductsButton": "BROWSE PRODUCTS",
    "browseProductsButtonAr": "تصفح المنتجات",
    "sec2Title":"Why Ramsis",
    "sec2TitleAr":"لماذا رامسيس",
    "sec2array":[
      {
        title: "competitive prices",
        text: "We offer very competitive prices with high quality products to support our customers worldwide.",
        id: 1,
      },
      {
        title: "global trade routes",
        text: "Egypt is at the heart of global trade routes, thus it provides easy access to Europe, Middle East and Africa.",
        id: 2,
      },
      {
        title: "trade agreements",
        text: "Egypt has trade agreements with Arab countries, European Union and COMESA countries which help cutting cost down.",
        id: 3,
      },
    ],
    "sec2arrayAR":[
      {
        title: "أسعار تنافسية",
        text: "نحن نقدم أسعاراً تنافسية للغاية مع منتجات عالية الجودة لدعم عملائنا في جميع أنحاء العالم.",
        id: 1,
      },
      {
        title: "طرق التجارة العالمية",
        text: "مصر تقع في قلب طرق التجارة العالمية، مما يوفر سهولة الوصول إلى أوروبا والشرق الأوسط وأفريقيا.",
        id: 2,
      },
      {
        title: "اتفاقيات تجارية",
        text: "لدى مصر اتفاقيات تجارية مع الدول العربية والاتحاد الأوروبي ودول الكوميسا مما يساعد في خفض التكاليف.",
        id: 3,
      },
    ],
    "aboutTitle":"What We Do?",
    "aboutTitleAR":"ما الذي نقوم به؟",
    "aboutText":"Have a look at our products.",
    "aboutTextAR":"ألقِ نظرة على منتجاتنا.",
    "aboutLink":"OUR Products >",
    "aboutLinkAR":"منتجاتنا >",
    "aboutTitle2":"Our Location",
    "aboutTitle2AR":"موقعنا",
    "aboutText2":"21 Youssef Wahbi, El Daher, Cairo, Egypt",
    "aboutText2AR":"21 يوسف وهبي، الظاهر، القاهرة، مصر",
    "aboutLink2":"SEE LOCATION >",
    "aboutLink2AR":"عرض الموقع >",
    "aboutMainSubheader":"RAMSIS INDUSTRY",
    "aboutMainSubheaderAR":"رامسيس للصناعة",
    "aboutMainHeader":"Making organization easy!",
    "aboutMainHeaderAR":"نجعل التنظيم سهلاً!",
    "aboutMainText":"Established in 1981, Ramsis Industry is a partnership family business company that strives to be one the largest and most reliable manufacturer of stationery products in the region. For more than 40 years, we have developed, produced and distributed our products in the global and local market. We have reached a high level of expertise making our products competitive in all markets.",
    "aboutMainTextAR":"تأسست في عام 1981، شركة رامسيس للصناعة هي شركة عائلية شراكة تسعى لتكون واحدة من أكبر وأوثق الشركات المصنعة لمنتجات الأدوات المكتبية في المنطقة. لأكثر من 40 عامًا، قمنا بتطوير وإنتاج وتوزيع منتجاتنا في السوقين العالمي والمحلي. لقد وصلنا إلى مستوى عالٍ من الخبرة مما يجعل منتجاتنا تنافسية في جميع الأسواق.",
    "aboutMainButton":"Our Products",
    "aboutMainButtonAR":"منتجاتنا",
    "contactHeader":"Send us a message",
    "contactHeaderAR":"أرسل لنا رسالة",
    "contactSubHeader":"We're waiting to hear from you.",
    "contactSubHeaderAR":"نحن في انتظار سماع أخبارك.",
    "contactMail":"Email",
    "contactMailAR":"البريد الإلكتروني",
    "contactMessage":"Message",
    "contactMessageAR":"الرسالة",
    "contactSend":"Send",
    "contactSendAR":"إرسال",
};
